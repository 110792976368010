.serviceItem__serviceContentContainer {
  margin-left: 3%;
  margin-right: 3%;
  padding-bottom: 3%;
}

.serviceItem__contentToRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.serviceItem__serviceItemsContainer {
  width: 730px;
  height: 370px;
  border-radius: 40px;
  background: radial-gradient(ellipse, #6066ff 100%, #000000 0%);
  position: relative;
  margin-top: 3%;
}

.serviceItem__itemsLinearGradientContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  border-radius: 40px;
  display: flex;
}

.serviceItem__itemsLinearGradientContainer_even {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  display: flex;
  border-radius: 40px;
}

.serviceItem__itemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 65px;
  right: -120px;
  left: 80px;
}

.serviceItem__itemsContainer_even {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 65px;
  right: 80px;
  left: -120px;
}

.serviceItem__serviceTextContainer {
  width: 60%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.serviceItem__serviceHeaderContainer {
  color: white;
  font-size: 26px;
  font-weight: 600;
}

.serviceItem__ServiceDescriptionContainer {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.serviceItem__serviceImageContainer {
  width: 240px;
  height: 240px;
  position: relative;
}

.serviceItem__itemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.serviceItem__imageGradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  border-radius: 40px;
}

.serviceItem__serviceContentContainer_small_screen {
  display: none;
}

@media (max-width: 900px) {
  .serviceItem__serviceItemsContainer {
    width: 600px;
    height: 250px;
  }

  .serviceItem__itemsContainer {
    top: 25px;
    right: -100px;
    left: 60px;
  }

  .serviceItem__itemsContainer_even {
    top: 25px;
    right: 60px;
    left: -100px;
  }

  .serviceItem__serviceTextContainer {
    height: 200px;
  }

  .serviceItem__serviceHeaderContainer {
    font-size: 25px;
    font-weight: 600;
  }

  .serviceItem__ServiceDescriptionContainer {
    font-size: 14px;
    font-weight: 400;
  }

  .serviceItem__serviceImageContainer {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 740px) {
  .serviceItem__serviceItemsContainer {
    width: 550px;
    height: 230px;
  }

  .serviceItem__itemsContainer {
    top: 25px;
    right: -90px;
    left: 50px;
  }

  .serviceItem__itemsContainer_even {
    top: 25px;
    right: 50px;
    left: -90px;
  }

  .serviceItem__serviceTextContainer {
    height: 180px;
  }

  .serviceItem__serviceHeaderContainer {
    font-size: 20px;
    font-weight: 600;
  }

  .serviceItem__ServiceDescriptionContainer {
    font-size: 13px;
    font-weight: 400;
  }

  .serviceItem__serviceImageContainer {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 670px) {
  .serviceItem__serviceItemsContainer {
    width: 430px;
    height: 200px;
  }

  .serviceItem__itemsContainer {
    top: 25px;
    right: -70px;
    left: 30px;
  }

  .serviceItem__itemsContainer_even {
    top: 25px;
    right: 30px;
    left: -70px;
  }

  .serviceItem__serviceTextContainer {
    height: 150px;
  }

  .serviceItem__serviceHeaderContainer {
    font-size: 20px;
    font-weight: 600;
  }

  .serviceItem__ServiceDescriptionContainer {
    font-size: 12px;
    font-weight: 300;
  }

  .serviceItem__serviceImageContainer {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 530px) {
  .serviceItem__serviceItemsContainer {
    display: none;
  }

  .serviceItem__serviceContentContainer_small_screen {
    width: 90%;
    height: auto;
    border-radius: 30px;
    background: linear-gradient(to top right, #000000, #6066ff);
    margin-top: 15%;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .serviceItem__serviceImageContainer_small_screen {
    width: 122px;
    height: 122px;
    border-radius: 30px;
    object-fit: contain;
    overflow: hidden;
    position: relative;
    top: -61px;
  }

  .serviceItem__itemImage_small_screen {
    width: 100%;
    height: 100%;
  }

  .serviceItem__imageGradient {
    border-radius: 30px;
  }

  .serviceItem__serviceHeaderContainer_small_screen {
    color: white;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    top: -30px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }

  .serviceItem__ServiceDescriptionContainer_small_screen {
    color: white;
    font-size: 14px;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}

@media (max-width: 470px) {
  .serviceItem__serviceHeaderContainer_small_screen {
    font-size: 22px;
    line-height: 25px;
  }

  .serviceItem__ServiceDescriptionContainer_small_screen {
    font-size: 13px;
    line-height: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 390px) {
  .serviceItem__serviceHeaderContainer_small_screen {
    font-size: 20px;
    line-height: 22px;
  }

  .serviceItem__ServiceDescriptionContainer_small_screen {
    font-size: 12px;
    line-height: 16px;
  }
}


