.clientScreen__main__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clientScreen__liner__gradient__toBottom {
  width: 100%;
  height: 60vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 1));
  position: relative;
}

.clientScreen__radial__gradient__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: radial-gradient(
    at bottom left,
    rgba(96, 102, 255, 0),
    rgba(96, 102, 255, 1)
  );
}

.clientScreen__liner__gradient__toRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.clientScreen__items__container {
  position: relative;
  top: -35vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 30px;
}

.clientScreen__header {
  font-size: 48px;
  line-height: 50px;
  font-weight: 800;
}

.clientScreen__clients__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.clientScreen__nextIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.clientScreen__next__icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.clientScreen__next__icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 470px) {
  .clientScreen__items__container {
    width: 90%;
  }
}
