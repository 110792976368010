@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
}

.plus-jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.playfair-display {
  font-family: "Playfair Display SC", serif;
}

.manrop {
  font-family: "Manrope", sans-serif;
}
