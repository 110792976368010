.jobsScreen__job__container {
  width: 100%;
  height: 322px;
  background: radial-gradient(
    at top right,
    rgba(96, 102, 255, 1),
    rgba(0, 0, 0, 0)
  );
  border-radius: 28px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jobsScreen__job__details__container {
  width: 100%;
  height: 180px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 18px;
}

.jobsScreen__job__img__container {
  height: 144px;
  border-radius: 14px;
  overflow: hidden;
}

.jobsScreen__job__img {
  width: 178px;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  transform: scale(1.3) translateY(0px);
  transition: transform 0.3s ease-in-out;
}

.jobsScreen__job__img__container:hover .jobsScreen__job__img {
  transform: scale(1.4) translateY(0px);
}

.jobsScreen__jobText__container {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 60px;
}

.jobsScreen__header {
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 3px;
}

.jobscreen__full__header {
  display: none;
}

.jobsScreen__description {
  height: 42px;
  width: 372px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  color: #e9e9e9;
}

.jobsScreen__job__highllights_small_screen_container {
  display: none;
}

.jobsScreen__description_small_screen {
  display: none;
}

.jobsScreen__job_image_header_small_screen {
  display: none;
}

.jobsScreen__seperator__line {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 95%;
  border-bottom-width: 1px;
  border-bottom-color: #797cc9;
}

.jobsScreen__job__highllights__container {
  width: 95%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.jobsScreen__job__highllights__container1_small_screen {
  display: none;
}

.jobsScreen__job__highllights__container2_small_screen {
  display: none;
}

@media (max-width: 810px) {
  .jobsScreen__jobText__container {
    padding-left: 30px;
  }

  .jobsScreen__header {
    font-size: 24px;
    line-height: 26px;
  }

  .jobsScreen__description {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 742px) {
  .jobsScreen__jobText__container {
    padding-left: 15px;
  }
}

@media (max-width: 695px) {
  .jobsScreen__description {
    height: 42px;
    width: 360px;
  }
}

@media (max-width: 590px) {
  .jobsScreen__job__container {
    height: auto;
    border-radius: 26px;
    padding: 10px;
    padding-bottom: 30px;
  }

  .jobsScreen__job__details__container {
    flex-direction: column;
    height: auto;
    padding-left: 10px;
  }

  .jobsScreen__job__img__container {
    display: none;
  }

  .jobsScreen__jobText__container {
    display: none;
  }

  .jobsScreen__job_image_header_small_screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .jobsScreen__job__img__container_small_screen {
    height: 92px;
    border-radius: 12px;
    margin-top: 10px;
    overflow: hidden;
  }

  .jobsScreen__job__img_smal_screen {
    width: 144px;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .jobsScreen__jobText__container_small_screen {
    margin-left: 20px;
  }

  .jobsScreen__header_small_screen {
    font-size: 26px;
    line-height: 28px;
    font-weight: 700;
  }

  .jobsScreen__description_small_screen {
    display: block;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    color: #e9e9e9;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .jobsScreen__seperator__line {
    width: 90%;
  }

  .jobsScreen__job__highllights__container {
    display: none;
  }

  .jobsScreen__job__highllights_small_screen_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    gap: 20px;
  }

  .jobsScreen__job__highllights__container1_small_screen, .jobsScreen__job__highllights__container2_small_screen {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}

@media (max-width: 420px) {
  .jobsScreen__header_small_screen {
    font-size: 21px;
    line-height: 28px;
  }

  .jobsScreen__description_small_screen {
    font-size: 12px;
    line-height: 16px;
  }

  .jobsScreen__seperator__line {
    width: 95%;
  }

  .jobsScreen__job__highllights_small_screen_container {
    width: 95%;
  }
}
