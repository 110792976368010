.question_answer_container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.question_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  height: 80px;
  border-top-width: 1px;
}

.drop_down_img {
  width: 17.5px;
  height: 7.5px;
  object-fit: contain;
  cursor: pointer;
}

.drop_down_img:hover {
  opacity: 0.7;
}

.answer_container {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 30px;
  padding-bottom: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.45s ease-in-out;
}

.answer_container.show {
  max-height: 350px;
  padding-bottom: 20px;
}

.answer_container.hide {
  max-height: 0;
  padding-bottom: 0;
}

@media (max-width: 976px) {
  .question_container {
    font-size: 17px;
  }
}


@media (max-width: 900px) {
  .question_container {
    font-size: 16px;
  }

  .answer_container {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
}

@media (max-width: 410px) {
  .question_container {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }

  .answer_container {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }
}