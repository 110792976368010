.contactMainContainer {
  display: flex;
  flex-direction: column;
}

.contactGradientContainer {
  background: linear-gradient(to right, #26154b, #6066ff);
  height: 65vh;
  display: flex;
  justify-content: center;
  position: relative;
}

.heading__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: white;
  margin-top: -5%;
}

.headerContainer {
  font-size: 60px;
  font-weight: 600;
  line-height: 66px;
}

.subHeaderContainer {
  width: 470px;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
}

.contactUs_imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 450px;
  border-radius: 60px;
  align-self: center;
  position: absolute;
  top: 63%;
  overflow: hidden;
}

.contactUs_main_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px;
  position: relative;
  top: -15%;
}

.conatctUsImage_gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -15%;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.9)
  );
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactUs_items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: 50%;
  margin-top: 5%;
  color: white;
  gap: 5%;
}

.items_container {
  /* width: 25%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.address_container {
  /* width: 40%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.map_container {
  width: 20%;
  height: 80%;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border-width: 7px;
  border-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.item_heading_Conatinar {
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
}

.contacts_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.contactScreen__number__container {
  display: flex;
  flex-direction: column;
}

.mail_Img {
  width: 20px;
  height: 20px;
}

.insta_Img {
  width: 30px;
  height: 30px;
  margin-left: -5px;
}

.fb_Img {
  width: 30px;
  height: 23px;
  margin-left: -5px;
}

.insta_txt {
  margin-left: -5px;
}

.item_descr_container {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.questions_container {
  margin-top: 18%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.ques_header_container {
  font-size: 48px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 35px;
}

.question_items_container {
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.half_items_container {
  width: 46%;
  flex-direction: column;
  justify-content: space-between;
}

.lets_talk_main_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lets_talk_gradient_container {
  width: 60%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  background: linear-gradient(to right, #26154b, #6066ff);
  border-radius: 60px;
  padding-left: 1px;
  padding-right: 1px;
}

.lets_talk_container {
  display: flex;
  width: 100%;
  height: 158px;
  background-color: black;
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.lets_talk_text_container {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
}

.lets_talk_button_container {
  width: 260px;
  height: 76px;
  border-radius: 30px;
  background: linear-gradient(to right, #26154b, #6066ff);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  cursor: pointer;
}

.lets_talk_button_container:hover {
  opacity: 0.8;
}

.mail_icon_image {
  width: 24px;
  height: 22px;
  object-fit: contain;
}

.button_text {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

@media (max-width: 1156px) {
  .questions_container {
    margin-top: 22%;
    margin-bottom: 40px;
  }
}

@media (max-width: 1056px) {
  .contactUs_imageContainer {
    height: 400px;
    top: 63%;
  }

  .contactUs_items_container {
    width: 80%;
    height: 30%;
  }

  .items_container {
    justify-content: space-between;
  }

  .address_container {
    justify-content: space-evenly;
    width: 35%;
    gap: 15px;
  }

  .map_container {
    width: 25%;
    height: 100%;
  }

  .item_descr_container {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }

  .lets_talk_text_container {
    font-size: 27px;
    line-height: 32px;
  }
}

@media (max-width: 976px) {
  .lets_talk_gradient_container {
    height: 150px;
  }

  .lets_talk_container {
    height: 148px;
  }

  .lets_talk_text_container {
    font-size: 25px;
    font-weight: 700;
    line-height: 28px;
  }

  .lets_talk_button_container {
    width: 230px;
    height: 76px;
    gap: 15px;
  }

  .ques_header_container {
    font-size: 45px;
    line-height: 45px;
  }
}

@media (max-width: 900px) {
  .ques_header_container {
    font-size: 38px;
    line-height: 42px;
  }

  .lets_talk_gradient_container {
    height: 140px;
  }

  .lets_talk_container {
    height: 138px;
  }

  .lets_talk_text_container {
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
  }

  .lets_talk_button_container {
    width: 210px;
    height: 67px;
    gap: 15px;
    border-radius: 25px;
  }
}

@media (max-width: 850px) {
  .contactUs_items_container {
    width: 90%;
    height: 35%;
  }

  .contactUs_imageContainer {
    width: 90%;
    height: 370px;
  }

  .map_container {
    width: 25%;
    height: 100%;
  }

  .items_container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .address_container {
    width: 38%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

@media (max-width: 800px) {
  .lets_talk_gradient_container {
    height: 115px;
  }

  .lets_talk_container {
    height: 113px;
  }

  .lets_talk_text_container {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
  }

  .lets_talk_button_container {
    width: 190px;
    height: 57px;
    border-radius: 20px;
  }
}

@media (max-width: 685px) {
  .contactUs_imageContainer {
    width: 90%;
    height: 300px;
  }

  .lets_talk_gradient_container {
    height: 100px;
    border-radius: 35px;
  }

  .lets_talk_container {
    height: 98px;
    border-radius: 35px;
    justify-content: space-evenly;
  }

  .lets_talk_text_container {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }

  .lets_talk_button_container {
    width: 150px;
    height: 50px;
    border-radius: 16px;
  }

  .item_heading_Conatinar {
    font-size: 22px;
    font-weight: 600;
    line-height: 23px;
  }

  .emailContainer {
    font-size: 12px;
    line-height: 16px;
  }

  .item_descr_container {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 600px) {
  .map_container {
    display: none;
  }

  .contactUs_items_container {
    justify-content: space-around;
    height: 40%;
  }

  .contactUs_imageContainer {
    height: 270px;
    top: 72%;
  }

  .ques_header_container {
    font-size: 30px;
    line-height: 38px;
  }

  .question_items_container {
    flex-direction: column;
    align-items: center;
  }

  .half_items_container {
    width: 100%;
  }
}

@media (max-width: 547px) {
  .lets_talk_gradient_container {
    height: 300px;
    width: 90%;
    border-radius: 40px;
  }

  .lets_talk_container {
    height: 298px;
    border-radius: 40px;
    flex-direction: column;
  }

  .lets_talk_text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
  }

  .lets_talk_button_container {
    width: 260px;
    height: 76px;
    border-radius: 30px;
  }

  .contactUs_imageContainer {
    height: 230px;
    top: 72%;
    border-radius: 30px;
  }

  .contactUs_imageContainer {
    display: flex;
    width: 80%;
    height: 580px;
    border-radius: 30px;
    top: 72%;
  }

  .contactUs_items_container {
    flex-direction: column;
    justify-content: space-around;
    align-items: self-start;
    height: auto;
    gap: 30px;
    padding-top: 70px;
  }

  .questions_container {
    margin-top: 80%;
  }

  .items_container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
  }

  .address_container {
    width: 100%;
  }

  .map_container {
    display: block;
    width: 100%;
  }

  .contactUs_main_img {
    border-radius: 30px;
  }

  .conatctUsImage_gradient {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .headerContainer {
    font-size: 50px;
    line-height: 60px;
  }

  .subHeaderContainer {
    width: 85%;
  }
}

@media (max-width: 455px) {
  .items_container{
    margin-top: 50px;
  }

  .lets_talk_gradient_container {
    height: 200px;
  }

  .lets_talk_container {
    height: 198px;
  }

  .lets_talk_text_container {
    font-size: 21px;
    line-height: 30px;
  }

  .lets_talk_button_container {
    width: 220px;
    height: 72px;
  }

  .contactUs_imageContainer {
    width: 90%;
    height: 500px;
    top: 72%;
  }

  .contactUs_items_container {
    flex-direction: column;
    justify-content: space-around;
    align-items: self-start;
    height: auto;
    gap: 30px;
    padding-top: 100px;
  }

  .questions_container {
    margin-top: 90%;
  }

  .address_container {
    gap: 10px;
  }

  .headerContainer {
    font-size: 40px;
    line-height: 50px;
  }

  .subHeaderContainer {
    width: 85%;
  }

  .item_heading_Conatinar {
    font-size: 18px;
    line-height: 20px;
  }

  .ques_header_container {
    font-size: 25px;
    line-height: 30px;
    margin-top: 15px;
  }
}

@media (max-width: 360px) {
  .headerContainer {
    font-size: 35px;
    line-height: 40px;
  }

  .questions_container {
    margin-top: 100%;
  }

  .ques_header_container {
    font-size: 23px;
    line-height: 30px;
  }
}

/* @media (max-width: 500px) {
  .lets_talk_gradient_container {
    width: 90%;
    height: 110px;
    border-radius: 30px;
  }

  .lets_talk_container {
    height: 108px;
    border-radius: 30px;
  }

  .lets_talk_text_container {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }

  .lets_talk_button_container {
    width: 170px;
    height: 60px;
    border-radius: 20px;
    gap: 15px;
  }

  .mail_Img {
    display: none;
  }
}

@media (max-width: 410px) {
  .lets_talk_gradient_container {
    width: 95%;
    height: 100px;
  }

  .lets_talk_container {
    height: 98px;
  }

  .lets_talk_text_container {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
  }

  .lets_talk_button_container {
    width: 155px;
    height: 55px;
    border-radius: 17px;
  }

  .ques_header_container {
    font-size: 26px;
    line-height: 38px;
  }

  .item_heading_Conatinar {
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
  }

  .emailContainer {
    font-size: 10px;
    line-height: 16px;
  }

  .mail_Img {
    width: 17px;
    height: 17px;
  }

  .item_descr_container {
    font-size: 10px;
    line-height: 16px;
  }
} */
