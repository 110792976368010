.trustedByFirstRow {
  height: 63px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 30px;
  gap: 10px;
}

.trustedBySecondRow {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
  gap: 10px;
}

.trustedImg {
    height: 100%;
    width: 120px;
    object-fit: contain;
}

@media (max-width: 600px) {
  .trustedImg {
    height: 70%;
    width: 100px;
  }

  .trustedByFirstRow {
    gap: 10px;
  }

  .trustedBySecondRow {
    gap: 10px;
  }
}
