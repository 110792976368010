.serviceScreen__servicesMainContainer {
  background: radial-gradient(circle, #6066ff 0%, #000000 100%);
  margin-left: 0;
  margin-right: 0;
  position: relative;
  padding-top: 150px;
}

.serviceScreen__topLinearGradientContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: -1;
}

.serviceScreeen__header {
  color: white;
  font-size: 48px;
  line-height: 50px;
  font-weight: 800;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media (max-width: 530px) {
  .serviceScreen__servicesMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .serviceScreeen__header {
    margin-bottom: 30px;
  }
}

@media (max-width: 465px) {
  .serviceScreeen__header {
    font-size: 40px;
    line-height: 45px;
    font-weight: 800;
  }
}

@media (max-width: 380px) {
  .serviceScreeen__header {
    font-size: 35px;
    line-height: 40px;
  }
}

@media (max-width: 350px) {
  .serviceScreeen__header {
    font-size: 30px;
    line-height: 35px;
  }
}
