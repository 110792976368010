.privacy_policy_main_container {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.privacy_policy_linerGradient_toBottom {
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 1));
}

.privacy_policy_radial_gradient_right_corner {
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: radial-gradient(
    circle at top right,
    rgba(96, 102, 255, 0.7),
    rgba(96, 102, 255, 0) 60%
  );
}

.privacy_policy_linerGradient_toRight {
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2));
}

.privacy_policy_main_text_container {
  position: relative;
  margin-top: 23vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 85%;
  color: white;
  padding-bottom: 100px;
  padding-left: 3%;
  padding-right: 3%;
}

.privacy_policy_header_container {
  font-size: 48px;
  line-height: 50px;
  font-weight: 800;
}

.privacy_policy_description_container {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}
