.subServiceContainer {
  width: 244px;
  height: 244px;
  border-radius: 40px;
  position: relative;
  cursor: pointer;
}

.subServiceContainer:hover {
  opacity: 0.9;
}

.serviceImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  border-radius: 40px;
}

.serviceText {
  position: absolute;
  color: white;
  left: 10%;
  bottom: 10%;
  font-size: 22px;
  font-weight: 500;
}

@media (max-width: 1056px) {
  .subServiceContainer {
    width: 200px;
    height: 200px;
    border-radius: 20px;
  }

  .gradientOverlay {
    border-radius: 20px;
  }

  .serviceText {
    left: 7%;
    bottom: 7%;
    font-size: 16px;
    font-weight: 400;
  }

  .serviceImg {
    border-radius: 20px;
  }
}

@media (max-width: 962px) {
  .subServiceContainer {
    width: 170px;
    height: 170px;
    border-radius: 15px;
  }

  .gradientOverlay {
    border-radius: 15px;
  }

  .serviceText {
    left: 7%;
    bottom: 7%;
    font-size: 16px;
    font-weight: 400;
  }

  .serviceImg {
    border-radius: 15px;
  }
}

@media (max-width: 800px) {
  .subServiceContainer {
    width: 100%;
    max-width: 600px;
    height: 250px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gradientOverlay {
    border-radius: 40px;
  }

  .serviceText {
    text-align: center;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: 500;
  }

  .serviceImg {
    width: 100%;
    height: 100%;
    border-radius: 40px;
  }
}

@media (max-width: 625px) {
  .serviceText {
    top: 58%;
  }
}
