.contactButtonContainer {
  display: flex;
  cursor: pointer;
  color: white;
}

.contactButton {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 30px;
  font-weight: 700;
  border-radius: 20px;
  background: linear-gradient(to right, #26154b, #6066ff);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.15);
}

.contactButton:hover {
  opacity: 0.9;
}
