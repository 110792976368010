.terms_and_conditions_main_container {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.terms_and_conditions_linerGradient_toBottom {
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 1));
}

.terms_and_conditions_radial_gradient_right_corner {
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: radial-gradient(
    circle at top right,
    rgba(96, 102, 255, 0.7),
    rgba(96, 102, 255, 0) 60%
  );
}

.terms_and_conditions_linerGradient_toRight {
  position: absolute;
  width: 100%;
  height: 120vh;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2));
}

.terms_and_conditions_main_text_container {
  position: relative;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 70%;
  color: white;
  padding-bottom: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.terms_and_conditions_header_container {
  font-size: 48px;
  line-height: 50px;
  font-weight: 800;
}

.terms_and_conditions_description_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.terms_and_conditions_description_header {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}

.terms_and_conditions_description_middle {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 731px) {
  .terms_and_conditions_main_text_container {
    width: 85%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .terms_and_conditions_header_container {
    font-size: 40px;
    line-height: 43px;
  }
}

@media (max-width: 488px) {
  .terms_and_conditions_header_container {
    font-size: 32px;
    line-height: 38px;
  }
}
