.nav-scrolled {
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6));
  transition: background 1s ease;
}

.navbar-toggle {
  display: none;
}

.navItem_tranlate_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-items {
  display: flex;
}

.translate_container {
  width: 200px;
  height: 35px;
  border-radius: 10px;
  background-color: #191919;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 20px;
}

.translateImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.google_translate_image {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.languagesContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.language {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 20px;
  cursor: pointer;
  color: white;
}

.language:hover {
  color: #6066ff;
  font-weight: 700;
}

.language.active {
  color: #6066ff;
  font-weight: 700;
}

@media (min-width: 1119px) {
  .navbar-items {
    display: flex;
  }

  .navbar-toggle {
    display: none;
  }
}
@media (max-width: 1119px) {
  .navbar-items {
    display: none;
  }

  .navbar-toggle {
    display: block;
    margin-left: 20px;
  }
}

.isNotOpen_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.isOpen_container {
  position: fixed;
  top: 100px;
  right: 3%;
  width: 150px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;
  gap: 10px;
  padding: 10px;
}

@media (max-width: 464px) {
  .translateImageContainer {
    display: none;
  }

  .translate_container {
    width: 135px;
    justify-content: center;
  }

  .navbar-toggle {
    margin-left: 10px;
  }
}

@media (max-width: 398px) {
  .translate_container {
    width: 110px;
  }
  .navbar-toggle {
    margin-left: 5px;
  }
}

@media (max-width: 356px) {
  .nlc_logo_image_container {
    width: 95px;
    height: 50px;
  }
}
