.cardStyle {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profileText {
  display: flex;
  flex-direction: column;
  /* margin-left: 10px; */
}

.profileName {
  font-size: 16px;
  font-weight: 700;
}

.profileDescription {
  font-size: 12px;
  font-weight: 400;
}

.ratingDescription {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
}

@media (max-width: 1036px) {
  .cardStyle {
    width: 100%;
    gap: 20px;
  }

  .ratingDescription {
    font-size: 15px;
    font-weight: 400;
  }

  .profileImg {
    width: 75px;
    height: 75px;
  }

  .profileText {
    margin-left: 15px;
  }

  .profileName {
    font-size: 20px;
    font-weight: 800;
  }

  .profileDescription {
    font-size: 16px;
    font-weight: 400;
  }
}
