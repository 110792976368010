.imageContainer {
  display: flex;
  position: relative;
  cursor: pointer;
}

.imageContainer:hover {
  opacity: 0.9;
}

.image {
  width: 420px;
  height: 392px;
  object-fit: cover;
  border-radius: 40px;
}

.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  border-radius: 40px;
}

.text {
  position: absolute;
  color: white;
  left: 10%;
  bottom: 10%;
  font-size: 35px;
  font-weight: 800;
}

@media (max-width: 1056px) {
  .image {
    c: 300px;
    max-height: 300px;
    border-radius: 40px;
  }

  .text {
    font-size: 24px;
    left: 10%;
    bottom: 10%;
    font-weight: 400;
  }
}

@media (max-width: 962px) {
  .image {
    width: 600px;
    max-height: 600px;
    border-radius: 40px;
  }

  .text {
    font-size: 40px;
    left: 10%;
    bottom: 10%;
    font-weight: 800;
  }
}
